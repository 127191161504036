.Step_1 {
  input {
    margin-bottom: 30px;
    padding: 0 30px;
    height: 60px;
    width: 100%;
    background-color: white;
    border: 2px solid #BDBDBD;
    border-radius: 100px;
    font-size: 16px;
    font-weight: 400;
    color: black;
  }
  .prof_list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    @media (max-width: 800px) {
      margin-bottom: 10px;
      gap: 10px;
    }
  }
  .item {
    position: relative;

    width: calc(20% - 16px);
    height: 40px;
    padding: 12px 10px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;

    background-color: white;
    border: 2px solid #BDBDBD;
    border-radius: 20px;

    font-weight: 400;
    color: black;
    text-align: center;
    transition: 0.3s;
    cursor: pointer;
    @media (max-width: 800px) {
      width: 100%;
    }
    & > div {
      width: calc(100% - 27px);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    svg {
      min-width: 22px;
      min-height: 22px;
    }
    &.active {
      background-color: #3077FF;
      svg {
        display: none;
      }
      & > div {
        width: 100%;
      }
    }
    &.preload {
      &::after {
        content: "";
        position: absolute;
        width: 10px;
        height: 10px;
        background-color: black;
        border-radius: 50%;
        top: 38%;
        left: 94%;
        transform: translateX(-50%);
        animation: moveAround 1s linear infinite;
      }
    }
  }
  @keyframes moveAround {
    0% { opacity: 0 }
    50% { opacity: 1 }
    100% { opacity: 0 }
  }
}
[data-theme="dark"] {
  .Step_1 {
    .item {
      background-color: #4F5464;
      color: white;
      &.active {
        background-color: #3077FF;
      }
      &.preload {
        &::after {
          background-color: #ffffff;
        }
      }
    }
    input {
      background-color: #4F5464;
      color: #BDBDBD;
    }
  }

}