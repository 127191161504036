.KeyFeatures {
  position: relative;
  margin-top: 140px;
  @media (max-width: 1020px) {
    margin-top: 70px;
  }
  .title {
    margin-bottom: 20px;
    font-size: 64px;
    text-align: center;
    @media (max-width: 1020px) {
      font-size: 50px;
      margin-bottom: 30px;
    }
    @media (max-width: 700px) {
      font-size: 24px;
    }
  }
  .subtitle {
     margin-bottom: 60px;
     font-size: 20px;
     text-align: center;
     color: #6a6a6a;
    font-weight: 400;
    @media (max-width: 700px) {
      font-size: 16px;
    }
  }

  .wrapper {
    overflow: hidden; // Скрываем лишние элементы
    position: relative;
  }

  .gallery {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    .item {
      padding: 20px;
      position: relative;
      width: calc(50% - 20px);
      height: 340px;
      background-color: white;
      //border: 2px solid #3F4351;
      //border-image-source: linear-gradient(180deg, rgba(52, 56, 67, 0) 25%, rgba(131, 141, 169, 0.3) 100%);
      border-radius: 24px;
      overflow: hidden;
      @media (max-width: 1020px) {
        padding: 20px;
      }
      &.mobile {
        display: none;
        @media (max-width: 1020px) {
          display: block;
        }
      }

      .text_block {
        max-width: 500px;
        position: absolute;
        z-index: 1;

        .first_row {
          margin-bottom: 12px;
          font-size: 20px;
          //color: #FFFFFF;
        }

        .second_row {
          font-size: 18px;
          color: #757A88;
        }
        @media (max-width: 1020px) {
          max-width: 380px;
          .first_row {
            font-size: 16px;
          }
          .second_row {
            font-size: 16px;
          }
        }
      }

      .image {
        position: absolute;
        bottom: -20px;
        right: -10px;
        max-width: 100%;
        height: auto;
        &.first, &.second, &.third, &.fourth, &.fifth, &.sixth, &.seventh, &.eighth {
          position: absolute;
        }
        &.third, &.second {
          bottom: -40px;
        }
        &.fourth {
          bottom: -70px;
        }
        &.fifth {
          bottom: -40px;
          right: 20px;
        }
        &.seventh {
          right: 20px;
        }
        @media (max-width: 1020px) {
          &.first {
            max-width: 188%;
            inset: 180px 0px 0px 20px;
          }
          &.second {
            max-width: 200%;
            inset: 150px 0px 0px 20px;
          }
          &.third {
            display: none;
            &.white {
              display: inline-block;
            }
          }
          &.fourth {
            min-width: 200%;
            inset: 150px 0px 0px 20px;
          }
          &.fifth {
            max-width: 90%;
            inset: 140px 0px 0px 36px;
          }
          &.sixth {
            color: #bdbdbd;
          }
          &.seventh {
            inset: 130px 0px 0px 20px;
            max-width: 95%;
          }
          &.eighth {
            color: #bdbdbd;
          }
        }
      }
    }
  }

  // Для мобильных разрешений бегущая строка
  @media (max-width: 1020px) {
    .gallery {
      flex-wrap: nowrap; // Убираем перенос
      gap: 10px;
      animation: scroll 20s linear infinite;

      .item {
        width: 300px; // Фиксированная ширина элементов
        flex-shrink: 0;
      }
    }

    @keyframes scroll {
      0% {
        transform: translateX(0);
      }
      100% {
        transform: translateX(-100%);
      }
    }
    @media (max-width: 700px) {
      .gallery {
        animation: scroll 10s linear infinite;
      }
    }
  }
}

[data-theme="dark"] {
  .KeyFeatures {
    .subtitle {
      color: #BDBDBD;
    }
    .gallery {
      .item {
        background-color: #111016;
        border: 2px solid #3F4351;
        .image {
          &.third {
            display: inline-block;
            &.white {
              display: none;
            }
          }
        }
      }
    }
  }
}