.Button {
  height: 40px;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  //font-family: 'SFPro';
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  color: #1E1F26;
  background-color: #fff;
  cursor: pointer;
  &.theme {
    color: #fff;
    background-color: #1E1F26;
  }
}

[data-theme="dark"] {
  .Button {
    &.theme {
      color: #1E1F26;
      background-color: #fff;
    }
  }
}
