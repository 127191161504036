.Questions {
  margin-top: 120px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    margin-bottom: 80px;
    font-size: 64px;
    text-align: center;
    @media (max-width: 1020px) {
      font-size: 24px;
      margin-bottom: 40px;
    }
    @media (max-width: 700px) {
      margin-bottom: 20px;
    }
  }
  .wrapper {
    max-width: 860px;
    .categories {
      position: absolute;
      left: 0;
      display: flex;
      flex-direction: column;
      gap: 30px;
      @media (max-width: 1300px) {
        position: static;
        flex-direction: row;
        flex-wrap: wrap;
        row-gap: 10px;
      }
      .category {
        color: #757A88;
        font-size: 20px;
        &.active {
          color: #16151B;
        }
      }
    }
    .questions {

      .question {
        padding: 30px 0;
        border-top: 1px solid #475067;
        &:first-child {
          border: none;
        }
        .name_wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
          &.link {
            text-decoration: underline;
          }
        }
        .name {
          margin-bottom: 24px;
          font-size: 32px;
          font-weight: 400;
          @media (max-width: 1020px) {
            font-size: 20px;
          }
        }
        .icon {
          path {
            stroke: #16151B;
          }
        }
        .text {
          font-size: 20px;
          height: 0;
          overflow: hidden;
          @media (max-width: 1020px) {
            font-size: 16px;
          }
          &.show {
            height: unset;
          }
        }
      }
    }
  }

}
[data-theme="dark"] {
  .Questions {
    .wrapper {
      .categories {
        .category {
          color: #757A88;
          &.active {
            color: #FFFFFF;
          }
        }
      }
      .questions {
        .question {
          .icon {
            path {
              stroke: white;
            }
          }
        }
      }
    }
  }
}