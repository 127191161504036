.Generate {
  margin-bottom: 1px;
  position: relative;
  width: 1160px;
  height: 480px;
  @media (max-width: 1200px) {
    width: 700px;
    //height: 400px;
  }
  @media (max-width: 800px) {
    width: 320px;
    height: unset;
  }
  //overflow: hidden;
  .slider {
    display: flex;
    gap: 1500px;
    transition: 0.5s;
    &.second {
      transform: translateX(calc(-1500px - 1160px));
    }
    &.third {
      transform: translateX(calc(-3000px - 2320px));
    }
    @media (max-width: 1200px) {
      gap: 800px;
      &.second {
        transform: translateX(calc(-800px - 700px));
      }
      &.third {
        transform: translateX(calc(-1600px - 1400px));
      }
    }
    @media (max-width: 800px) {
      gap: 400px;
      &.second {
        transform: translateX(calc(-400px - 320px));
      }
      &.third {
        transform: translateX(calc(-800px - 640px));
      }
    }
  }
  .slide {
    position: relative;
    padding: 40px;
    min-width: 1160px;
    max-width: 1160px;
    height: 480px;
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 40px;
    opacity: 0;
    transition: 0.5s;
    .icon {
      position: absolute;
      top: 40px;
      right: 40px;
      display: flex;
      align-items: center;
      gap: 10px;
      svg {
        width: 30px;
        height: 30px;
        fill: black;
      }
      & > div {
        font-size: 32px;
        color: black;
      }
      @media (max-width: 800px) {
        top: 14px;
        right: 130px;
      }

    }
    .title {
      margin-bottom: 15px;
      font-size: 32px;
      color: black;
      @media (max-width: 800px) {
        font-size: 20px;
        opacity: 0;
      }
    }
    .subtitle {
      margin-bottom: 15px;
      font-size: 20px;
      font-weight: 400;
      color: #6a6a6a;
    }
    @media (max-width: 1200px) {
      min-width: 700px;
      max-width: 700px;
      //height: 400px;
    }
    @media (max-width: 800px) {
      padding: 10px;
      min-width: 320px;
      max-width: 320px;
      height: unset;
    }
    &.active {
      opacity: 1;
    }
  }
  .nav {
    margin-top: auto;
    display: flex;
    align-items: center;
    gap: 20px;
    @media (max-width: 800px) {
      padding: 0 5px;
      margin-bottom: 10px;
    }
    .nav_text {
      @media (max-width: 800px) {
        display: none;
      }
    }
    .page {
      font-size: 20px;
      font-weight: 400;
      color: black;
    }
    .btn {
      height: 40px;
      width: fit-content;
      padding: 0 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      background-color: #3077FF;
      font-size: 16px;
      font-weight: 400;
      color: white;
      transition: 0.3s;
      cursor: pointer;
      @media (max-width: 800px) {
        height: 30px;
        font-size: 14px;
      }
      &.space_left {
        margin-left: auto;
      }
      &.back {
        background-color: #7F7F7F;
      }
      &.disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }
    .lang_changer {
      padding: 8px 11px;
      position: relative;
      display: flex;
      gap: 5px;
      align-items: center;
      justify-content: center;
      //width: 40px;
      width: 73px;
      max-height: 33px;
      background-color: #7f7f7f;//#27292F
      border: 1px solid var(--background_lighten-dark);
      border-radius: 16px;
      transition: all .3s;
      cursor: pointer;
      @media (max-width: 800px) {
        position: absolute;
        right: 5px;
        bottom: 55px;
      }
      svg {
        border-radius: 2px;
      }
      div {
        text-align: center;
        color: white;//#757A88
        transition: all .3s;
        cursor: pointer;
        &:hover,
        &.active {
          transition: all .3s;
          color: white;
        }
      }
      &.open {
        transition: all .3s;
        width: 93px;
        //flex-direction: column;
        //max-height: 80px;
        //height: 100%;
        //div:not(:last-child)  {
        //  padding-bottom: 2px;
        //  border-bottom: 1px solid var(--background_lighten-dark);
        //}
      }
    }
  }
  .blur_circle {
    width: 110%;
    height: 500px;
    border-radius: 50%;
    background: radial-gradient(circle, #1667ff -300%, rgba(0, 123, 255, 0) 100%);
    filter: blur(75px);
    position: absolute;
    top: 0;
    left: -5%;
    z-index: 1;
    pointer-events: none;
    @media (max-width: 1200px) {
      min-width: 700px;
      max-width: 700px;
      height: 400px;
    }
    @media (max-width: 800px) {
      display: none;
    }
  }
}
.modal {
  padding: 20px;
  padding-top: 40px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;

  border-radius: 20px;
  background-color: white;
  color: black;
  font-size: 22px;
  text-align: center;
}
//#98a0bb
[data-theme="dark"] {
  .Generate {
    .nav {
      .page {
        color: white;
      }
      .lang_changer {
        background-color: #27292F;
        div {
          color: #757A88;
        }
      }
    }
    .slide {
      background-color: #2F303A;
      border: 1px solid #BDBDBD;
      .title {
        color: white;
      }
      .subtitle {
        color: #BDBDBD;
      }
      .icon {
        svg {
          fill: white;
        }
        & > div {
          font-size: 32px;
          color: white;
        }
      }
    }
    .blur_circle {
      background: radial-gradient(circle, #7CA3ED -300%, rgba(0, 123, 255, 0) 100%);
      filter: blur(75px);
    }
  }
  .modal {
    color: white;
    background-color: #1D1E25;
    border: 1px solid #F2F3F5;
  }
}