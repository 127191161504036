.carousel {
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  position: relative;
  width: 100%;
  .content {
    display: flex;
    flex-wrap: nowrap;
    animation: scroll linear infinite;
  }
}


@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}
