.Footer {
  padding-top: 120px;
  padding-bottom: 30px;
  font-size: 20px;
  color: #8A8B90;
  background-color: #F2F3F5;
  .wrapper {
    display: flex;
  }
  .end {
    margin-top: 30px;
    padding-top: 30px;
    border-top: 1px solid #272727;
  }
  .col {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .first {
    width: 34%;
    align-items: flex-start;
    .icons {
      display: flex;
      align-items: center;
      gap: 10px;
      a {
        display: contents;
        cursor: pointer;
      }
    }
    .text {
      font-weight: 400;
      max-width: 300px;
      color: #6a6a6a;
    }
  }
  .second {
    width: 22%;
    align-items: flex-start;
    color: #6a6a6a;
    font-weight: 400;
    div:first-child {
      color: black;
    }
    div:not(:first-child) {
      cursor: pointer;
    }
  }
  .logoW, .logoB {
    width: 250px;
  }
  .logoW {
    display: none;
  }
  .icon_color {
    fill: #1E1F26;
  }
  @media (max-width: 1020px) {
    .wrapper {
      flex-direction: column;
    }
    .col {
      width: 100%;
      align-items: center;
      text-align: center;
      &:not(:first-child) {
        margin-top: 20px;
        padding-top: 20px;
        border-left: none;
        border-top: 1px solid #343843;
      }
    }
    .first {
      .icons {
        svg {
          width: 32px;
          height: 32px;
        }
      }
    }
    .second {
      align-items: center;
      text-align: center;
    }
    .end {
      text-align: center;
    }
  }
}
[data-theme="dark"] {
  .Footer {
    background-color: #111016;
    .second {
      color: #bdbdbd;
      div:first-child {
        color: white;
      }
    }
    .text {
      color: #bdbdbd;
    }
    .logoB {
      display: none;
    }
    .logoW {
      display: inline-block;
    }
    .icon_color {
      fill: #F2F3F5;
    }
  }
}