.RadarDiagram {
  margin-top: 120px;
  .title {
    font-size: 64px;
    text-align: center;
  }
  .subtitle {
    width: 60%;
    margin: 36px auto 10px auto;
    font-size: 20px;
    text-align: center;
    color: #6a6a6a;
    font-weight: 400;
  }
  .icon {
    width: 24px;
    min-width: 24px;
    min-height: 24px;
    height: 24px;
    display: none;
    padding-right: 5px;
    path {
      fill: black;
    }
    @media (max-width: 550px) {
      display: block;
    }
  }
  .wrapper {
    width: fit-content;
    position: relative;
    margin: 0 auto;
    .text {
      position: absolute;
      font-size: 20px;
      font-weight: 500;
      &.first {
        text-align: right;
        max-width: 260px;
        top: 17%;
        left: 0%;
      }
      &.second {
        text-align: left;
        max-width: 260px;
        top: 17%;
        right: -0%;
      }
      &.third {
        text-align: right;
        max-width: 306px;
        top: 51%;
        left: -10%;
      }
      &.fourth {
        text-align: left;
        max-width: 295px;
        top: 51%;
        right: -8%;
      }
      &.fifth {
        text-align: right;
        max-width: 357px;
        top: 85%;
        left: 0%;
      }
      &.sixth {
        text-align: left;
        max-width: 272px;
        top: 85%;
        right: 4%;
      }
      @media (max-width: 1500px) {
        font-size: 16px;
        &.second {
          max-width: 175px;
        }
        &.fourth {
          max-width: 240px;
        }
        &.fifth {
          max-width: 225px;
        }
        &.sixth {
          max-width: 218px;
        }
      }
      @media (max-width: 1100px) {
        font-size: 14px;
        &.first {
          max-width: 140px;
        }
        &.second {
          max-width: 150px;
        }
        &.third {
          max-width: 180px;
        }
        &.fourth {
          max-width: 208px;
        }
        &.sixth {
          max-width: 192px;
        }
      }
      @media (max-width: 800px) {
        font-size: 12px;
        &.first {
          max-width: 120px;
        }
        &.second {
          max-width: 130px;
        }
        &.third {
          max-width: 155px;
          left: -18%;
        }
        &.fourth {
          max-width: 180px;
          right: -18%;
        }
        &.fifth {
          max-width: 170px;
        }
        &.sixth {
          max-width: 164px;
        }
      }
      @media (max-width: 550px) {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        position: static;
        font-size: 14px;
        &.first,&.second,&.third,&.fourth,&.fifth,&.sixth {
          max-width: 250px;
        }
      }
    }
  }
  img {
    max-width: 75%;
    margin: 0 auto;
    @media (max-width: 1500px) {
      max-width: 800px;
    }
    @media (max-width: 1100px) {
      max-width: 600px;
    }
    @media (max-width: 800px) {
      max-width: 400px;
    }
    @media (max-width: 550px) {
      max-width: 250px;
    }
    &.white {
      display: block;
    }
    &.black {
      display: none;
    }
  }
  @media (max-width: 1020px) {
    .title {
      font-size: 40px;
    }
    .subtitle {
      font-size: 18px;
    }
  }
  @media (max-width: 600px) {
    .title {
      font-size: 24px;
    }
    .subtitle {

      margin: 18px auto 10px auto;
      font-size: 16px;
    }
  }
}
[data-theme="dark"] {
  .RadarDiagram {
    .subtitle {
      color: #BDBDBD;
    }
    .icon {
      path {
        fill: white;
      }
    }
    img {
      &.white {
        display: none;
      }
      &.black {
        display: block;
      }
    }
  }
}