.Header {
  position: sticky;
  top: 0;
  padding: 0 40px;
  height: 88px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #111016;
  color: #FFFFFF;
  z-index: 5;
  @media (max-width: 1200px) {
    padding: 0 16px;
    height: 60px;
  }
  .label {
    height: 45px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    border-radius: 50px;
    background-color: #ffffff;
    color: black;
    cursor: pointer;
  }
  .hidden {
    display: none;
  }
  .skip_btn {
    width: unset;
    padding: 0 10px;
    background-color: #474C5A;
    color: white;
    //@media (max-width: 1100px) {
    //  display: none;
    //}
    &.auth {
      //width: unset;
      background-color: white;
      color: black;
    }
  }
  .navigation {
    display: flex;
    align-items: center;
    gap: 70px;
    @media (max-width: 1400px) {
      gap: 40px;
    }
    @media (max-width: 1100px) {
      display: none;
    }
    &__item {
      cursor: pointer;
      font-size: 20px;
      color: #BDBDBD;
      @media (max-width: 1400px) {
        font-size: 16px;
      }
    }
  }
  .controls {
    display: flex;
    align-items: center;
    gap: 24px;
    img {
      cursor: pointer;
    }
    .menu_btn {
      display: none;
      @media (max-width: 1100px) {
        display: block;
      }
    }
  }
  @media (max-width: 1100px) {
    .controls .skip_btn {
      display: none;
    }
  }
  .sidebar {
    position: absolute;
    width: 0;
    right: 0;
    top: 0;

    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 10px;

    transition: 0.5s;
    background-color: #FFFFFF;
    color: #16151B;
    overflow: hidden;
    &.show {
      width: 100%;
      padding: 20px;
      //transform: translate(-100%, 0);
    }
    svg {
      position: absolute;
      top: 20px;
      right: 20px;
      path {
        stroke: #1E1F26;
      }
    }
    .item {
      font-size: 20px;
    }
  }
}
[data-theme="dark"] {
  .Header {
    .sidebar {
      background-color: #111016;
      color: #FFFFFF;
      svg path {
        stroke: #FFFFFF;
      }
    }
  }
}