.ModeChanger {
  margin: 24px 0 50px 0;
  display: flex;
  justify-content: center;
  gap: 12px;
  @media (max-width: 800px) {
    margin: 18px 0 25px 0;
  }
  .item {
    height: 45px;
    padding: 0 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #474C5A;
    border: 1px solid #7e879d;
    border-radius: 30px;
    color: #FFFFFF;
    cursor: pointer;
    @media (max-width: 600px) {
      height: 32px;
      padding: 0 10px;
      font-size: 11px;
    }
    &.active {
      background-color: #3077FF;
      box-shadow: 0 0 25px 0 #3077FF;
      border: none;
    }
  }
}