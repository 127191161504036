.OurClients {
  position: relative;
  margin-top: -600px;
  @media (max-width: 1400px) {
    margin-top: -500px;
  }
  @media (max-width: 1020px) {
    margin-top: -350px;
  }
  @media (max-width: 700px) {
    margin-top: -200px;
  }
  .title {
    margin-bottom: 40px;
    font-size: 20px;
    font-weight: 400;
    color: #6a6a6a;
    text-align: center;
    @media (max-width: 700px) {
      margin-bottom: 20px;
    }
  }
  .galleryWrapper {
    overflow: hidden;
    position: relative;
    width: 100%;
    padding: 12px 0;
  }
  .gallery {
    display: flex;
    justify-content: center;
    gap: 10px;
    //@media (max-width: 1500px) {
    //  animation: scroll 20s linear infinite;
    //  justify-content: unset;
    //}
    //@media (max-width: 700px) {
    //  animation: scroll 10s linear infinite;
    //}
    .item {
      width: 200px;
      min-width: 200px;
      height: 120px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      background-color: #fff;
      //box-shadow: 0 3px 10px 0 rgba(149, 84, 250, 0.75);
      &:first-child {
        margin-left: 10px;
      }
      &.mobile {
        display: none;
        @media (max-width: 1500px) {
          display: flex;
        }
      }
      img {
        max-height: 95%;
        max-width: 95%;
        height: auto;
      }
    }
  }
  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }
}

[data-theme="dark"] {
  .OurClients {
    .title {
      color: #bdbdbd;
    }
  }
}