.Step_3 {
  //position: relative;
  .plan {
    margin-bottom: 8px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    border: 2px solid #BDBDBD;
    border-radius: 20px;
    background-color: white;
    padding: 20px;
    @media (max-width: 800px) {
      padding: 6px;
    }
    svg {
      position: absolute;
      height: 88px;
      width: 88px;
      fill: black;
      &.hide {
        display: none;
      }
      &.animation {
        animation: opacityAnimation 1s linear infinite;
      }
      @keyframes opacityAnimation {
        0% { opacity: 0 }
        50% { opacity: 1 }
        100% { opacity: 0 }
      }
    }
    pre {
      line-height: 1.2;
      max-height: 230px;
      min-height: 230px;
      width: 100%;
      font-size: 20px;
      font-weight: 400;
      color: black;
      overflow-y: auto;
      overflow-x: hidden;
      @media (max-width: 1200px) {
        overflow-x: auto;
      }
      @media (max-width: 800px) {
        max-height: 270px;
      }
      &::-webkit-scrollbar {
        width: 6px; /* Ширина скроллбара */
      }
      &::-webkit-scrollbar-track {
        background: #f0f0f0; /* Цвет фона */
        border-radius: 3px;
      }
      &::-webkit-scrollbar-thumb {
        background: #a0a0a0; /* Цвет ползунка */
        border-radius: 3px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #888; /* Цвет при наведении */
      }
    }
  }
}
[data-theme="dark"] {
  .Step_3 {
    .plan {
      background-color: #4F5464;
      svg {
        fill: white;
      }
      pre {
        color: #BDBDBD;
      }
    }
  }
}