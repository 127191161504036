.Step_2 {
  .vac_list {
    display: flex;
    gap: 30px;
    @media (max-width: 800px) {
      margin-bottom: 10px;
      flex-wrap: wrap;
      gap: 15px;
    }
  }
  .item {
    position: relative;
    padding: 20px;
    height: 250px;
    width: calc(33.3% - 20px);
    display: flex;
    flex-direction: column;
    gap: 15px;
    background-color: white;
    border: 2px solid #BDBDBD;
    border-radius: 20px;
    color: black;
    @media (max-width: 800px) {
      width: 100%;
    }
    &.preload {
      &::after {
        content: "";
        position: absolute;
        width: 14px;
        height: 14px;
        background-color: black;
        border-radius: 50%;
        top: 5%;
        left: 95%;
        transform: translateX(-50%);
        animation: moveAround 1s linear infinite;
        @keyframes moveAround {
          0% { opacity: 0 }
          50% { opacity: 1 }
          100% { opacity: 0 }
        }
      }
    }
    .head {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .title {
      max-width: 240px;
      font-size: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .icon {
      svg {
        width: 30px;
        height: 30px;
      }
    }
    .skill_list {
      max-height: 134px;
      display: flex;
      flex-wrap: wrap;
      gap: 6px;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 6px; /* Ширина скроллбара */
      }

      &::-webkit-scrollbar-track {
        background: #f0f0f0; /* Цвет фона */
        border-radius: 3px;
      }

      &::-webkit-scrollbar-thumb {
        background: #a0a0a0; /* Цвет ползунка */
        border-radius: 3px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #888; /* Цвет при наведении */
      }
    }
    .skill {
      position: relative;
      max-width: calc(100% - 5px);
      height: 28px;
      padding: 6px 8px;

      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 5px;

      background-color: white;
      border: 2px solid #BDBDBD;
      border-radius: 10px;

      font-size: 14px;
      font-weight: 400;

      overflow: hidden;
      transition: 0.3s;
      color: black;
      cursor: pointer;
      & > div {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      svg {
        min-width: 18px;
        min-height: 18px;
        max-width: 18px;
        max-height: 18px;
      }
      &.active {
        background-color: #3077FF;
        svg {
          display: none;
        }
        & > div {
          width: 100%;
        }
      }
    }
    .footer {
      margin-top: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .salary {
        max-width: 90px;
        font-size: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .company {
        max-width: 200px;
        font-size: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #BDBDBD;
      }
    }
  }
}
[data-theme="dark"] {
  .Step_2 {
    .item {
      border: 2px solid #BDBDBD;
      background-color: #4F5464;
      color: white;
      &.preload {
        &::after {
          background-color: #ffffff;
        }
      }
    }
    .skill {
      background-color: #4F5464;
      border: 1px solid #BDBDBD;
      color: white;
    }
  }
}