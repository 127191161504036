.HowWorks {
  margin-top: 140px;
  @media (max-width: 1020px) {
    margin-top: 70px;
  }
  .title {
    margin-bottom: 20px;
    font-size: 64px;
    text-align: center;
    @media (max-width: 1020px) {
      font-size: 50px;
      margin-bottom: 30px;
    }
    @media (max-width: 700px) {
      font-size: 24px;
    }
  }
  .subtitle {
    margin-bottom: 60px;
    font-size: 20px;
    text-align: center;
    color: #6a6a6a;
    font-weight: 400;
  }
  .wrapper {
    display: flex;
    align-items: center;
    gap: 30px;
    @media (max-width: 1020px) {
      flex-direction: column;
    }
  }
  .item {
    height: 250px;
    width: calc(33.3% - 20px);
    padding: 40px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: #ffffff;
    border-radius: 24px;
    @media (max-width: 1020px) {
      width: 100%;
    }
    .top {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 20px;
      .icon {
        min-width: 50px;
        width: 50px;
        min-height: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        //border: 2px solid #BDBDBD;
        border-radius: 20px;
        //background-color: #111016;
        svg {
          width: 50%;
          height: 50%;
        }
      }
    }
    .text {
      font-size: 32px;
      font-weight: 500;
      @media (max-width: 1300px) {
        font-size: 28px;
      }
      @media (max-width: 1020px) {
        font-size: 32px;
      }
      @media (max-width: 500px) {
        font-size: 28px;
      }
    }
    .subtext {
      font-size: 20px;
      font-weight: 500;
      color: #6a6a6a;
      @media (max-width: 1300px) {
        font-size: 16px;
      }
      @media (max-width: 1020px) {
        font-size: 20px;
      }
      @media (max-width: 500px) {
        font-size: 16px;
      }
    }
  }
  .label {
    margin-left: auto;
    font-size: 48px;
    //color: #FFFFFF;
  }
  &.second {
    //color: #2A9471;
    .item {
      height: 92px;
      padding: 20px;
    }
    .label {
      margin-left: unset;
      font-size: 32px;
      color: #6a6a6a;
      @media (max-width: 1300px) {
        font-size: 24px;
      }
      @media (max-width: 700px) {
        font-size: 18px;
      }
    }
  }
}

[data-theme="dark"] {
  .HowWorks {
    .subtitle {
      color: #BDBDBD;
    }
    .item {
      background-color: #111016;
      border: 2px solid #272727;
      .subtext {
        color: #BDBDBD;
      }
      .top .icon {
        background-color: #FFFFFF;
        border: 2px solid #BDBDBD;
      }
    }
    &.second {
      .label {
        color: #BDBDBD;
      }
    }
  }
}