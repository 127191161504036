.Preview {
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  //overflow: hidden;
  .preview {
    margin-top: 400px;
    max-width: 1440px;
    width: 100%;
    object-fit: contain;
    border-radius: 24px;
    border: 1px solid white;
    @media (max-width: 1020px) {
      margin-top: 250px;
    }
    @media (max-width: 500px) {
      opacity: 0;
      //margin-top: 300px;
    }
  }
  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    max-width: 1440px;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 25%, rgba(242, 243, 245, 1) 50%);
    pointer-events: none;
    @media (max-width: 700px) {
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 25%, rgba(242, 243, 245, 1) 60%);
    }
    @media (max-width: 500px) {
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 25%, rgba(242, 243, 245, 1) 70%);
    }
  }
  .text {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    position: absolute;
    //bottom: 35%;
    width: 100%;
    .first {
      max-width: 1040px;
      font-size: 80px;
      text-align: center;
    }
    .second {
      max-width: 840px;
      font-size: 20px;
      text-align: center;
      font-weight: 400;
      color: #6a6a6a;
    }
    .third {
      max-width: 640px;
      font-size: 18px;
      text-align: center;
      font-weight: 400;
      color: #757A88;
    }
    .try_free {
      //display: none;
    }
    @media (max-width: 1020px) {
      //bottom: 0;
      .first {
        max-width: 640px;
        font-size: 40px;
      }
      .second {
        max-width: 640px;
        font-size: 18px;
      }
      .third {
        display: none;
      }
      .try_free {
        //display: block;
      }
    }
    @media (max-width: 700px) {
      //bottom: -30%;
      .first {
        max-width: 90%;
        font-size: 32px;
      }
      .second {
        max-width: 90%;
        font-size: 16px;
      }
    }
    @media (max-width: 500px) {
      //bottom: -50%;
    }
  }
}

[data-theme="dark"] {
  .Preview {
    .text {
      .second {
        color: #BDBDBD;
      }
    }
    .preview {
      padding: 1px;
      border: none;
    }
    .overlay {
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 25%, #111016 50%);
      @media (max-width: 700px) {
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 25%, #111016 60%);
      }
      @media (max-width: 500px) {
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 25%, #111016 70%);
      }
    }
  }
}