.AICapabilities {
  margin-top: 120px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    position: relative;
    z-index: 2;
    padding-top: 140px;
    margin-bottom: 340px;
    max-width: 1000px;
    font-size: 64px;
    text-align: center;
    line-height: 1.4;
  }
  .subtitle {
    position: relative;
    z-index: 2;
    padding-bottom: 140px;
    margin: 50px auto 10px auto;
    max-width: 700px;
    font-size: 20px;
    text-align: center;
    color: #6a6a6a;
    font-weight: 400;
    line-height: 1.4;
  }
  @media (max-width: 1020px) {
    .title {
      font-size: 40px;
      padding-top: 100px;
    }
    .subtitle {
      font-size: 18px;
      padding-bottom: 100px;
    }
  }
  @media (max-width: 800px) {
    .title {
      padding-top: 0;
    }
    .subtitle {
      padding-bottom: 0;
    }
  }
  @media (max-width: 600px) {
    margin-top: 60px;
    .title {
      font-size: 24px;
    }
    .subtitle {
      margin: 18px auto 10px auto;
      font-size: 16px;
    }
  }
  @media (max-width: 500px) {
    .title {
      margin-bottom: 200px;
    }
    .subtitle {
      display: none;
    }
  }

  .wrapper {
    position: absolute;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .item {
    height: 70px;
    padding: 0 40px;
    position: absolute;
    background: white;
    box-shadow: 0 6px 25px -10px rgba(0, 0, 0, 0.75);
    border-radius: 20px;
    backdrop-filter: blur(10px);
    display: flex;
    align-items: center;
    gap: 10px;
    path {
      fill: black;
    }
    &.first {
      left: 60px;
      top: 300px;
    }
    &.second {
      right: 60px;
      bottom: 300px;
    }
    @media (max-width: 800px) {
      height: 50px;
      padding: 0 15px;
      &.first {
        left: 20px;
        top: 150px;
      }
      &.second {
        right: 20px;
        bottom: 150px;
      }
    }
    @media (max-width: 500px) {
      height: 50px;
      padding: 0 15px;
      &.first {
        left: 10px;
        top: 70px;
      }
      &.second {
        right: 10px;
        bottom: 50px;
      }
    }
  }
  .picture {
    position: absolute;
    max-width: 100%;
  }
}
[data-theme="dark"] {
  .AICapabilities {
    .subtitle {
      color: #BDBDBD;
    }
    .item {
      background: #FFFFFF1A;
      border: 1px solid white;
      path {
        fill: white;
      }
    }
  }
}