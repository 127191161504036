.TryFree {
  height: 500px;
  position: relative;
  margin-top: 80px;
  padding: 40px 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 28px;

  overflow: hidden;
  border-radius: 24px;
  border: 1px solid #272727;
  //background: linear-gradient(180deg, rgb(255 255 255) 0%, #3077FF 500%);
  background-color: white;
  .btn {
    position: relative;
    width: 200px;
  }
  .inp {
    width: unset;
  }
  img {
    position: absolute;
    inset: 0;
    max-width: 200%;
  }
  .pattern_white {

  }
  .pattern {
    display: none;
  }
  .blur_circle {
    width: 1000px;
    height: 1000px;
    border-radius: 50%;
    background: radial-gradient(circle, rgb(25 25 25 / 39%) 0%, rgba(0, 123, 255, 0) 100%);
    filter: blur(75px);
    position: absolute;
    top: -500px;
    @media (max-width: 1020px) {
      width: 500px;
      height: 500px;
      top: -250px;
    }
    @media (max-width: 1020px) {
      width: 300px;
      height: 300px;
      top: -150px;
    }
  }
  @media (max-width: 1500px) {
    height: 440px;
  }
  @media (max-width: 1200px) {
    height: 370px;
    padding: 40px;
  }
  @media (max-width: 1020px) {
    height: 300px;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
    gap: 15px;
    .btn {
      width: 100%;
    }
    .inp {
      width: 100%;
      input {
        width: 100%;
      }
    }
  }
  @media (max-width: 500px) {
    height: 200px;
  }
  .text_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    @media (max-width: 1020px) {
      gap: 10px;
    }
    @media (max-width: 500px) {
      display: none;
    }
  }
  .title {
    position: relative;
    font-size: 58px;
    text-align: center;
    &.blue {
      color: #3077FF;
      text-shadow: 0px 0px 30px rgba(48, 119, 255, 1), 0px 0px 40px rgba(48, 119, 255, 1), 0px 0px 50px rgba(48, 119, 255, 1);
    }
    @media (max-width: 1500px) {
      font-size: 42px;
    }
    @media (max-width: 1200px) {
      font-size: 36px;
    }
    @media (max-width: 1020px) {
      font-size: 18px;
    }
  }
  .subtitle {
    position: relative;
    font-size: 32px;
    text-align: center;
    color: #6a6a6a;
    font-weight: 400;
    @media (max-width: 500px) {
      font-size: 24px;
    }
  }

  &.second {
    .blur_circle {
      background: radial-gradient(circle, #3077FF 0%, rgba(0, 123, 255, 0) 100%);
    }
    @media (max-width: 500px) {
      .title {
        display: none;
      }
    }
  }
}

[data-theme="dark"] {
  .TryFree {
    background-color: #1D1E25;
    .subtitle {
      color: #BDBDBD;
    }
    .pattern_white {
      display: none;
    }
    .pattern {
      display: inline-block;
    }
    .blur_circle {
      background: radial-gradient(circle, #FFFFFF40 0%, rgba(0, 123, 255, 0) 100%);
    }
    &.second {
      .blur_circle {
        background: radial-gradient(circle, #3077FF 0%, rgba(0, 123, 255, 0) 100%);
      }
    }
  }
}