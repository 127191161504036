.YourPhone {
  margin: 0 auto;
  margin-top: 120px;
  .title {
    margin-bottom: 40px;
    font-size: 64px;
    text-align: center;
  }
  .subtitle {
    margin: 0 auto;
    margin-bottom: 40px;
    max-width: 1020px;
    font-size: 20px;
    text-align: center;
    color: #6a6a6a;
    font-weight: 400;
  }
  .buttons {
    margin-bottom: 40px;
    display: flex;
    justify-content: center;
    gap: 20px;
    .button {
      padding: 0 40px;
      height: 55px;
      width: 210px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 16px;

      border: 2px solid #272727;
      border-radius: 20px;
    }
  }
  svg path {
    fill: black;
  }
  .wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
  .overlay {
    //display: none;
    margin-bottom: -2px;
    position: absolute;
    width: 100%;
    height: 100%;
    max-width: 1440px;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 65%, rgb(242, 243, 245) 100%);
    pointer-events: none;
    z-index: 3;
  }
  .blur {
    position: absolute;
    max-width: 100%;
  }
  .picture {
    position: relative;
    z-index: 2;
  }
  @media (max-width: 1020px) {
    margin-top: 60px;
    .title {
      margin-bottom: 20px;
      font-size: 24px;
    }
    .subtitle {
      margin-bottom: 20px;
      font-size: 16px;
    }
    .buttons{
      flex-wrap: wrap;
    }
    .overlay {
      //display: block;
    }
    .picture {
      width: 240px;
    }
  }
}

[data-theme="dark"] {
  .YourPhone {
    .subtitle {
      color: #BDBDBD;
    }
    svg path {
      fill: white;
    }
    .overlay {
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 65%, #111016 100%);
    }
  }
}