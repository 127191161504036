.StartToday {
  margin-top: 140px;
  @media (max-width: 1020px) {
    margin-top: 70px;
  }
  .title {
    margin-bottom: 20px;
    font-size: 64px;
    text-align: center;
    @media (max-width: 1020px) {
      font-size: 50px;
      margin-bottom: 30px;
    }
    @media (max-width: 700px) {
      font-size: 24px;
    }
  }
  .subtitle {
    margin-bottom: 60px;
    font-size: 20px;
    text-align: center;
    color: #6a6a6a;
    font-weight: 400;
  }
  .wrapper {
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
  }
  .item {
    position: relative;

    padding: 50px;
    height: 300px;
    width: calc(38% - 15px);

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    overflow: hidden;
    border-radius: 24px;
    border: 2px solid #272727;
    svg {
      display: none;
    }
    &:nth-child(3n - 2) {
      width: calc(62% - 15px);
      .text, .subtext {
        max-width: 75%;
      }
      @media (max-width: 900px) {
        width: calc(50% - 15px);
        .text, .subtext {
          max-width: 100%;
        }
      }
      @media (max-width: 600px) {
        width: 100%;
      }
      svg {
        width: 150%;
        position: absolute;
        inset: -120% 0 0 15%;
        display: block;
        @media (max-width: 1200px) {
          inset: -135% 0 0 15%;
        }
        @media (max-width: 1200px) {
          inset: -175% 0 0 15%;
        }
      }
    }
    &:nth-child(4) {
      svg {
        width: 150%;
        position: absolute;
        inset: -120% 0 0 -50%;
        display: block;
        @media (max-width: 1200px) {
          inset: -135% 0 0 -50%;
        }
        @media (max-width: 1200px) {
          inset: -175% 0 0 -50%;
        }
      }
    }
    .number, .text, .subtext {
      position: relative;
      z-index: 2;
    }
    .number {
      font-size: 64px;
    }
    .text {
      font-size: 32px;
    }
    .subtext {
      font-size: 20px;
    }
    @media (max-width: 1200px) {
      height: 260px;
      padding: 30px;
      .number {
        font-size: 50px;
      }
      .text {
        font-size: 26px;
      }
      .subtext {
        font-size: 18px;
      }
    }
    @media (max-width: 900px) {
      height: 220px;
      width: calc(50% - 15px);
      .text, .subtext {
        max-width: 100%;
      }
      .number {
        font-size: 40px;
      }
      .text {
        font-size: 22px;
      }
      .subtext {
        font-size: 14px;
      }
    }
    @media (max-width: 600px) {
      height: 200px;
      width: 100%;
      //.text, .subtext {
      //  max-width: 100%;
      //}
      //.number {
      //  font-size: 40px;
      //}
      //.text {
      //  font-size: 22px;
      //}
      //.subtext {
      //  font-size: 14px;
      //}
    }
  }
}

[data-theme="dark"] {
  .StartToday {
    .subtitle {
      color: #BDBDBD;
    }
  }
}